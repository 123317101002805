<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/14
  * @BSD
-->
<template>
  <van-popup v-model="show" position="bottom" class="detail-popup">
    <div class="popup-main">
      <div class="tab-wrap">
        <template v-for="(item, index) in chooseTab">
          <div class="tab-item" :key="index" @click="changeTab(item, index)">{{ item.name }}</div>
        </template>
      </div>
      <div class="content-wrap">
        <div
                class="scroll-div"
                scroll-y
                :scroll-top="scrollTop">
          <template v-for="(item, index) in curCity">
            <div class="ad-item" :class="{'active': itemActive(item)}" @click="getChildren(item)" :key="item.id">
              {{ item.name }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
  import { getProvinceList, getSublist } from '@/common/api/address.js'
  export default {
    name:"my-address",
    data() {
      return {
        scrollTop: 0,
        addressList: [],
        curCity: [],
        level: 0,
        chooseTab: [{
          name:'全国'
        }
        ],
        show: false
      };
    },
    mounted () {
      this.init()
    },
    methods: {
      itemActive (item) {
        return this.chooseTab.filter(tab => tab.id == item.id).length > 0
      },
      open() {
        this.show = true
      },
      init () {
        getProvinceList({
          success: res => {
            if (res.code == 200) {
              this.addressList = res.data.list
              this.curCity = res.data.list
            }
          }
        })
      },
      getChildren (item) {
        if (item.children) {
          this.curCity = item.children
          this.addTab(item)
        } else {
          getSublist({
            data: {
              pid: item.id
            },
            success: res => {
              if (res.code == 200) {
                if (res.data.list.length > 0) {
                  item.children = res.data.list
                  this.curCity = res.data.list
                  this.addTab(item)
                } else {
                  this.addTab(item)
                  console.log(this.chooseTab)
                  this.show = false
                  this.$emit('changeAddress', this.chooseTab)
                }
              }
            }
          })
        }
      },
      addTab (item) {
        if (this.chooseTab.filter(tab => tab.id == item.id).length == 0) {
          this.chooseTab.push({
            name: item.name,
            id: item.id,
            merger_name: item.merger_name,
            pid: item.pid
          })
          this.scrollTop = 0
        }
      },
      changeTab (item, index) {
        getSublist({
          data: {
            pid: item.id
          },
          success: res => {
            if (res.code == 200 && res.data.list.length > 0) {
              this.curCity = res.data.list
              this.chooseTab = this.chooseTab.splice(0, index + 1)
            }
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .popup-main {
    background: #FFFFFF;
    border-radius: 36px 36px 0 0;
    max-height: 972px;
    box-sizing: border-box;
    padding: 46px 40px 0;
    height: 972px;
  }

  .tab-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #e7e6e6;
    margin-bottom: 16px;
    & .tab-item {
      padding: 0 8px;
      margin: 0 8px;
      color: #6d6b6b;
      padding-bottom: 16px;
      font-size: 28px;
      &:last-child {
        position: relative;
        &::after {
          content: " ";
          width: 100%;
          background: #007AFF;
          height: 6px;
          position: absolute;
          left: 0;
          bottom: -2px;
        }
      }
    }
  }
  .content-wrap {
    height: 822px;
    // overflow-y: auto;
    width: 800px;
    & .scroll-div {
      height: 822px;
    }
    & .ad-item {
      font-size: 32px;
      color: #5b5a5a;
      height: 72px;
      line-height: 72px;
      &.active {
        color: #007AFF;
      }
    }
  }
</style>

