<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/10
  * @BSD
-->
<template>
  <div class="page-wrap pt188">
    <!-- 顶部 -->
    <top-bar-arrow>
      <div slot="title" class="title-image">
        编辑地址
      </div>
    </top-bar-arrow>
    <div class="main">
        <div class="form-item-panel form-item-select" @click="openAddress">
          <div class="left">
            <div class="text">省市区</div>
            <div class="tip">*</div>
          </div>
          <div class="right">
            <div v-if="addressName" class="text">{{ addressName }}</div>
            <template v-else>
              <div class="text">请选择</div>
              <van-icon name="arrow" size="18"/>
            </template>
          </div>
        </div>
        <div class="form-item-panel form-item-input">
          <div class="left">
            <div class="text">联系人</div>
            <div class="tip">*</div>
          </div>
          <div class="right">
            <van-field v-model="formData.contacts" placeholder="点击输入" />
          </div>
        </div>
        <div class="form-item-panel form-item-input">
          <div class="left">
            <div class="text">联系方式</div>
            <div class="tip">*</div>
          </div>
          <div class="right">
            <van-field v-model="formData.contact_phone" placeholder="点击输入" />
          </div>
        </div>
        <div class="remarks-info box-shadow">
          <div class="title">详细地址</div>
          <div class="input-wrap">
            <van-field
                    v-model="formData.address"
                    rows="4"
                    autosize
                    type="textarea"
                    maxlength="300"
                    placeholder="您所收货的详细地址"
                    show-word-limit
            />
          </div>
        </div>
        <div class="btn-line">
          <div class="btn-normal" @click="submitForm">编辑</div>
          <div class="btn-normal btn-delete" @click="deleteAddress">删除</div>
        </div>
      <my-address ref="myAddress" @changeAddress="changeAddress"></my-address>
    </div>
  </div>
</template>

<script>
  import {
    editAddress,
    deleteAddress
  } from '@/common/api/address.js'
  import { mapGetters, mapMutations } from 'vuex'
  import topBarArrow from '../../components/top-bar-arrow'
  import myAddress from '../../components/my-address'
  export default {
    components: {
      topBarArrow,
      myAddress
    },
    data() {
      return {
        formData: {
          contacts: '',
          contact_phone: '',
          address: ''
        },
        inputNum: 0,
        addressName: '',
        addressArr: [], // 地址对象
        province: '', // 省ID
        city: '', // 市ID
        region: '', // 区ID
        errorMsg: ''

      };
    },
    computed: {
      ...mapGetters({
        editAddress: 'getEditAddress'
      })
    },
    watch: {
    },
    mounted() {
      this.initEditAddress()
    },
    methods: {
      ...mapMutations(['UPDATE_CHOOSE_ADDRESS']),
      initEditAddress () {
        this.formData = {
          contacts: this.editAddress.contacts,
          contact_phone: this.editAddress.contact_phone,
          address: this.editAddress.address
        }
        this.inputNum = this.editAddress.address.length
        this.addressName = this.editAddress.pcr_address
        this.province = this.editAddress.province
        this.city = this.editAddress.city
        this.region = this.editAddress.region
      },
      openAddress() {
        this.$refs.myAddress.open()
      },
      inputChange(value) {
        this.inputNum = value.length || 0
        console.log(value)
      },
      changeAddress(address) {
        if (address.length == 4) {
          this.addressArr = address
          this.addressName = address[1].name + address[2].name + address[3].name
          this.province = address[1].id
          this.city = address[2].id
          this.region = address[3].id
        }
      },
      submitForm() {
        // 校验省市区
        if (!this.addressName) {
          this.errorMsg = '请选择省市区！'
          this.$refs.errPopup.open()
          return
        }
        // 校验联系人
        if (!this.formData.contacts) {
          this.errorMsg = '请输入联系人！'
          this.$refs.errPopup.open()
          return
        }
        // 校验手机号
        if (!this.formData.contact_phone) {
          this.errorMsg = '请输入联系方式！'
          this.$refs.errPopup.open()
          return
        }
        if (!(/^1[3456789]\d{9}$/.test(this.formData.contact_phone))) {
          this.errorMsg = '请输入正确的联系方式！'
          this.$refs.errPopup.open()
          return
        }
        //校验详细地址
        if (!this.formData.address) {
          this.errorMsg = '请输入详细地址！'
          this.$refs.errPopup.open()
          return
        }
        if (this.inputNum > 100) {
          this.errorMsg = '详细地址不能超过100个字符！'
          this.$refs.errPopup.open()
          return
        }

        editAddress({
          data: {
            province: this.province,
            city: this.city,
            region: this.region,
            contacts: this.formData.contacts,
            contact_phone: this.formData.contact_phone,
            address: this.formData.address,
            address_id: this.editAddress.id
          },
          success: res => {
            console.log(res)
            if (res.code == 200) {
              this.UPDATE_CHOOSE_ADDRESS({
                ...this.editAddress,
                province: this.province,
                city: this.city,
                region: this.region,
                contacts: this.formData.contacts,
                contact_phone: this.formData.contact_phone,
                address: this.formData.address,
                address_id: this.editAddress.id,
                ...res.data
              })
              this.$router.go(-2)
            }
          }
        })
      },
      deleteAddress() {
        deleteAddress({
          data: {
            address_id: this.editAddress.id
          },
          success: res => {
            if (res.code) {
              this.$router.go(-1)
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "./addressAddEdit.scss"
</style>

